var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.hasMultipleOffices
    ? _c(
        "app-form",
        { attrs: { id: "rooms-search-form", submitAction: _vm.search } },
        [
          _c(
            "div",
            { staticClass: "rooms-office" },
            [
              _c("app-dropdownlist", {
                attrs: {
                  id: "officeId",
                  placeholder: _vm.$t("offices.office"),
                  dataSource: _vm.offices,
                  fields: { text: "name", value: "id" },
                  cssClass: "e-field"
                },
                model: {
                  value: _vm.officeId,
                  callback: function($$v) {
                    _vm.officeId = $$v
                  },
                  expression: "officeId"
                }
              })
            ],
            1
          ),
          _c("app-button", { attrs: { cssClass: "e-primary rooms-search" } }, [
            _vm._v(" " + _vm._s(_vm.$t("shared.shortSearch")) + " ")
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }