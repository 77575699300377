/* eslint-disable */
import { UrlAdaptor } from '@syncfusion/ej2-data';
import { getCookie } from "tiny-cookie";
export class CustomUrlAdaptor extends UrlAdaptor {
    beforeSend(dm, request) {
        const token = sessionStorage.getItem("token") || localStorage.getItem("token");
        if (token != null)
            request.setRequestHeader("Authorization", `Bearer ${token}`);
        // Add XSRF token
        const xsrfToken = getCookie("XSRF-TOKEN");
        if (xsrfToken != null)
            request.setRequestHeader("X-XSRF-TOKEN", xsrfToken);
        request.withCredentials = true;
    }
}
